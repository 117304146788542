<template>
  <div>Processing login...</div>
</template>

<script>
import { ACCESS_TOKEN_KEY } from "@/modules/login/constants";
import { decode } from "@/filters";

export default {
  name: "EmployeeLogin",
  async mounted() {
    const userObj = decode(this.$route.query.access_token);
    const accessToken=userObj.token;
    const toPath = this.$route.query.to_path ? this.$route.query.to_path.toString() : "/";
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken.toString());
      this.$store.dispatch("userLogin");
      await this.$store.dispatch("fetchPermissions");
      this.$router.push(toPath);
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
